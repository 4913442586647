import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import HeaderPage from '../../utils/HeaderPage';
import Parser  from 'react-html-parser'

const ArtificialIntelligence = (props) => {
  // console.log(props)
  const TRADEMARK = props.data.TRADEMARK.nodes;
  const canonicalUrl = `/blogs/trademark-patents`;
  const seo = {};

  return (
    <Layout>
      <Seo title={ `Trademark - Protecting Your Brands ` } canonical={ canonicalUrl } seo={seo} />
      <HeaderPage headerTxt={'Trademark Patents'}></HeaderPage>
      <section className='mainSpacing2'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <ul>
                    {
                    TRADEMARK.map((node, index) => (
                      <li>
                        <a href={"/blog/" + node.slug} aria-current="true">
                            {node.title}
                        </a>
                      </li>
                    ))
                    }
                    </ul>
                </div>
            </div>
            <div className="mb-5"></div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    TRADEMARK: allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Trademark - Protecting Your Brands"}}}}}
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        uri
        title
        seo {
            metaDesc
            focuskw
        }
        slug
        date(fromNow: true)
      }
    }
  }
`

export default ArtificialIntelligence;
